import React, {
    useState
  } from 'react'  
import update from 'immutability-helper'
import {
    Card,
    TextField,
    Popover,
    FormLayout,
    ColorPicker,
    RangeSlider,
    LegacyStack,
    RadioButton,
    Button,
  } from "@shopify/polaris"
import FieldTitle from '../../../../../components/fieldTitle'
import { throttle } from 'lodash'
function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}
function TopBarTab(props) {
    const { state, setState, checkoutOfferType } = props;
    const [topBarBackgroundColorPopoverVisible, setTopBarBackgroundColorPopoverVisible] = useState(false)
    const [topBarIntroTextColorPopoverVisible, setTopBarIntroTextColorPopoverVisible] = useState(false)
    const [topBarBackgroundColorHsl, setTopBarBackgroundColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 91,
        alpha: 1,
      })
    const [introTextColorHsl, setIntroTextColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 0,
        alpha: 1,
      })
    const topBarIntroTextColorActivatorButton = (
        <TextField 
          label= {<FieldTitle title="Intro text color"/>}
          onFocus={() => setTopBarIntroTextColorPopoverVisible(true)} 
          value={state.introTextColor}
          onChange={(val) => setState(update(state, { introTextColor: { $set: val }})) }
          connectedLeft={
            <div 
              style={{ 
                borderRadius: 5,
                cursor: 'pointer',
                height: 36, 
                width: 40,
                marginRight: 8,
                backgroundColor: state.introTextColor,
                border: state.introTextColor.toLowerCase() === '#ffffff' && '1px solid #000000'
              }}
              onClick={() => setTopBarIntroTextColorPopoverVisible(true)} 
            />
          }
        />          
    )
    const topBarBackgroundColorActivatorButton = (
        <TextField 
          label={<FieldTitle title="Top bar background color"/>} 
          onFocus={() => setTopBarBackgroundColorPopoverVisible(true)} 
          value={state.topBarBackgroundColor}
          onChange={(val) => setState(update(state, { topBarBackgroundColor: { $set: val }})) }
          connectedLeft={
            <div 
              style={{ 
                borderRadius: 5,
                cursor: 'pointer',
                height: 36, 
                width: 40, 
                marginRight: 8,
                backgroundColor: state.topBarBackgroundColor,
                border: state.topBarBackgroundColor.toLowerCase() === '#ffffff' && '1px solid #000000'
              }}
              onClick={() => setTopBarBackgroundColorPopoverVisible(true)} 
            />
          }
        />
      )
    const onTopBarBackgroundColorHslColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || topBarBackgroundColorHsl.hue || 0,
          saturation: updatedColor.saturation || topBarBackgroundColorHsl.saturation || 0,
          brightness: updatedColor.brightness || topBarBackgroundColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        setTopBarBackgroundColorHsl(color)
        setState(update(state, { topBarBackgroundColor: { $set: hex }})) 
      }
    const onTopBarBackgroundColorChangeThrottled = throttle(onTopBarBackgroundColorHslColorChange, 250)
   
    const onIntroTextColorHslColorChange = (updatedColor) => {
      const color = {
        hue: updatedColor.hue || introTextColorHsl.hue || 0,
        saturation: updatedColor.saturation || introTextColorHsl.saturation || 0,
        brightness: updatedColor.brightness || introTextColorHsl.brightness || 0,
        alpha: 1,
      }
      const hex = hslToHex(
        color.hue, 
        color.saturation * 100, 
        color.brightness * 100,
      )
  
      setIntroTextColorHsl(color)
      setState(update(state, { introTextColor: { $set: hex }})) 
    }
  const onIntroTextColorChangeThrottled = throttle(onIntroTextColorHslColorChange, 250)
   
    return (
        <FormLayout>
          <LegacyStack wrap={false} alignment="trailing">
            <LegacyStack.Item fill>
              <TextField 
                label={<FieldTitle title="Intro text"/>}
                placeholder="People also bought" 
                onChange={(val) => setState(update(state, { introText: { $set: val }})) }
                value={state.introText}
                />
            </LegacyStack.Item>          
          </LegacyStack>
          <Popover
            active={topBarIntroTextColorPopoverVisible}
            activator={topBarIntroTextColorActivatorButton}
            onClose={() => setTopBarIntroTextColorPopoverVisible(false)}
            sectioned
          >                  
            <ColorPicker 
              color={introTextColorHsl}
              onChange={onIntroTextColorChangeThrottled}
            />
          </Popover>
          <Popover
            active={topBarBackgroundColorPopoverVisible}
            activator={topBarBackgroundColorActivatorButton}
            onClose={() => setTopBarBackgroundColorPopoverVisible(false)}
          >
            <ColorPicker 
              color={topBarBackgroundColorHsl}
              onChange={onTopBarBackgroundColorChangeThrottled}
            />
          </Popover>
          
        </FormLayout>
    )
}

export default TopBarTab;